import BackEnd from '@/sys/BackEnd/BanckEnd'
import User from '@/sys/Model/User/User'
export default class UserDAO {

    static async createUser(user: User) {
        return new Promise(async function(resolve,reject){
            try{
                BackEnd.function().createUser(user);
                await BackEnd.registerUser(user.email);
                BackEnd.sleep(500);
                BackEnd.sendResetPasswordEmail(user.email, true);
                resolve("Usuário criado com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_141: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readUser(email: String) {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readUser(email));
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_142: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readAllActiveUsers(accessLevel: Number) {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readAllActiveUsers(accessLevel));
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_143: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readAllConfirmedUsers() {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readAllConfirmedUsers());
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_144: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async updateUser(user: User, block: Boolean) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().updateUser(user, block);
                resolve("Usuário atualizado com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_145: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async deleteUser(user: User) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().deleteUser(user);

                //let realmUser = BackEnd.getUser(user.realmId);
                //BackEnd.deleteUser(realmUser);
                resolve("Usuário deletado com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_146: Favor checar sua conexão com a internet.");
            }
        });
    }
}

export {UserDAO}